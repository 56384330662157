import type { Colors } from 'styled-components';
import type { RichTextField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';
import type { heroAnimationsOptions } from '@yoweb/ui/components/sections/HeroSection/HeroWithColoredBackground';

export const HeroWithColoredBackgroundSliceType = 'hero_with_colored_background_slice';

// Required fields in slice
export const requiredPrimaryFields = ['title', 'animation', 'backgroundColor'] as const; // Array of all required primary fields.

type Primary = {
  title: string;
  subtitle?: RichTextField;
  label?: string;
  animation: keyof heroAnimationsOptions;
  backgroundColor: keyof Colors;
};

export type DefaultSliceResponse = PrismicSlice<
  typeof HeroWithColoredBackgroundSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  null,
  'default'
>;

export type WithSmallerAnimationSliceResponse = PrismicSlice<
  typeof HeroWithColoredBackgroundSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  null,
  'withSmallerAnimation'
>;

export type HeroWithColoredBackgroundSliceResponse =
  | DefaultSliceResponse
  | WithSmallerAnimationSliceResponse;
