import { PrismicRichText } from '@prismicio/react';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type HeroWithColoredBackgroundSliceResponse,
  requiredPrimaryFields,
} from './HeroWithColoredBackgroundSliceTypes';
import { HeroWithColoredBackground } from '@yoweb/ui/components/sections/HeroSection/HeroWithColoredBackground';
import { subtitleSerializer } from '@yoweb/prismic-slicemachine/utils/serializers';

type HeroWithColoredBackgroundProps = {
  slice: HeroWithColoredBackgroundSliceResponse;
};

const HeroWithColoredBackgroundSlice = ({ slice, ...rest }: HeroWithColoredBackgroundProps) => {
  const { title, subtitle, label, animation, backgroundColor } = slice.primary;

  const props = {
    title,
    subtitle: <PrismicRichText field={subtitle} components={subtitleSerializer} />,
    label,
    animation,
    backgroundColor,
    variation: slice.variation,
  };

  return <HeroWithColoredBackground {...props} {...rest} />;
};

export default withPrismicGuardian(HeroWithColoredBackgroundSlice, {
  primaryRequired: requiredPrimaryFields,
});
