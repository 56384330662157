import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import { type RoundedImageSliceResponse, requiredPrimaryFields } from './RoundedImageSliceTypes';
import { RoundedImage } from '@yoweb/ui/components/sections/RoundedImage';

type RoundedImageSliceProps = {
  slice: RoundedImageSliceResponse;
};

const RoundedImageSlice = ({ slice, ...rest }: RoundedImageSliceProps) => {
  const { image, title } = slice.primary;

  const props = {
    src: image.url ?? '',
    alt: image.alt ?? '',
    title,
  };

  return <RoundedImage {...props} {...rest} />;
};

export default withPrismicGuardian(RoundedImageSlice, {
  primaryRequired: requiredPrimaryFields,
});
