import Image, { type ImageProps } from 'next/legacy/image';
import styled from 'styled-components';

import { getRadii } from '@yoweb/ui/styles/utils/theme';

type PhotoProps = {
  // Height and width are optional props in ImageProps type.
  // We want to force it because we need fixed height and width
  // for the initial size of the image.
  height: number;
  width: number;
} & Omit<ImageProps, 'layout' | 'height' | 'width'> & {
    layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive';
  };

const Photo = ({ width, height, ...props }: PhotoProps) => (
  <PhotoWrapper
    width={width ?? 'auto'}
    height={height ?? 'auto'}
    layout="responsive"
    objectFit="cover"
    {...props}
  />
);

const PhotoWrapper = styled(Image)`
  && {
    border-radius: ${getRadii('medium')};
  }
`;

export default Photo;
