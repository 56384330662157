import type { ImageField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const RoundedImageSliceType = 'rounded_image_slice';

// Required fields in slice
export const requiredPrimaryFields = ['image'] as const; // Array of all required primary fields.

type Primary = {
  image: ImageField;
  title?: string;
};

export type RoundedImageSliceResponse = PrismicSlice<
  typeof RoundedImageSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>
>;
