import { useEffect, useState } from 'react';
import styled, { type Colors, css } from 'styled-components';

import { Lottie } from '@yoweb/ui/components/Lottie';
import AnimateIn, {
  StaggerElements,
  InView,
  STAGGER_DELAY,
  ScaleIn,
  DURATIONS,
} from '@yoweb/ui/components/AnimateIn';
import { Box } from '@yoweb/ui/components/Box';
import { Title, Label } from '@yoweb/ui/components/typography';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { getSpace, getColor } from '@yoweb/ui/styles/utils/theme';
import { Container } from '@yoweb/ui/components/containers';

export type heroAnimationsOptions = {
  aboutUs: string;
  careers: string;
  press: string;
};

const heroAnimations = {
  aboutUs: () => import('@yoweb/ui/lottieAnimations/about-us-hero-animation-data.json'),
  careers: () => import('@yoweb/ui/lottieAnimations/careers-hero-animation-data.json'),
  press: () => import('@yoweb/ui/lottieAnimations/press-hero-animation-data.json'),
};

type HeroWithColoredBackgroundType = {
  label?: string;
  title: string;
  subtitle?: ReactNode;
  animation: keyof heroAnimationsOptions;
  backgroundColor: keyof Colors;
  smallerAnimation?: boolean;
  variation: string;
};

export const HeroWithColoredBackground = ({
  label,
  title,
  subtitle,
  animation,
  backgroundColor = 'citrus',
  variation,
}: HeroWithColoredBackgroundType) => {
  const [lottieState, setLottieState] = useState<unknown>(undefined);

  useEffect(() => {
    const setupLottie = async () => {
      const lottieData = await heroAnimations[animation]();
      setLottieState(lottieData as unknown);
    };

    void setupLottie();
  }, [animation, setLottieState]);

  return (
    <InView>
      {(isInView) => (
        <AnimateIn
          isVisible={isInView}
          config={{
            opacity: { animate: true, duration: DURATIONS.d67 },
            y: { animate: false, offset: 0 },
          }}
        >
          <HeroSection backgroundColor={backgroundColor} data-testid="hero-with-colored-background">
            <HeroGrid withGrid>
              <HeroText>
                <StaggerElements isInView={isInView} initialDelay={250}>
                  <div>
                    {label && (
                      <Box>
                        <Label size={{ _: 'sm', lg: 'md' }} data-testid="hero-label">
                          {label}
                        </Label>
                      </Box>
                    )}
                    <Box pt={{ _: 18, md: 22 }}>
                      <Title
                        size={{ _: 'lg', md: 'xl', lg: 'xxl' }}
                        data-testid="hero-title"
                        whiteSpace="pre-wrap"
                      >
                        {title}
                      </Title>
                    </Box>
                  </div>
                  {subtitle && (
                    <Box pt={{ _: 'normal1', md: 'normal2' }}>
                      <Title size={{ _: 'xs', lg: 'sm' }} weight="regular" whiteSpace="pre-wrap">
                        {subtitle}
                      </Title>
                    </Box>
                  )}
                </StaggerElements>
              </HeroText>
              {variation === 'withSmallerAnimation' ? (
                <SmallerHeroImage data-testid="hero-animation">
                  <ScaleIn
                    isVisible={isInView}
                    config={{
                      scale: {
                        scale: 1.5,
                        duration: 0.24 + DURATIONS.d67 + STAGGER_DELAY / 1000,
                      },
                    }}
                  >
                    <Lottie animationData={lottieState} />
                  </ScaleIn>
                </SmallerHeroImage>
              ) : (
                <HeroImage data-testid="hero-animation">
                  <ScaleIn
                    isVisible={isInView}
                    config={{
                      scale: {
                        scale: 1.5,
                        duration: 0.24 + DURATIONS.d67 + STAGGER_DELAY / 1000,
                      },
                    }}
                  >
                    <Lottie animationData={lottieState} />
                  </ScaleIn>
                </HeroImage>
              )}
            </HeroGrid>
          </HeroSection>
        </AnimateIn>
      )}
    </InView>
  );
};
const HeroSection = styled.div<{ backgroundColor: keyof Colors }>`
  display: flex;
  padding-top: ${getSpace('xl2')};
  padding-bottom: ${getSpace('medium2')};
  text-align: center;
  min-height: 100vh;
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${getColor(backgroundColor)};
    `}

  ${media.md`
    padding-top: ${getSpace('xl2')};
    min-height: 100%;
  `}

  ${media.lg`
    align-items: center;
    text-align: left;
    padding-top:${pxToRem(0)};
    padding-bottom:${pxToRem(0)};
    min-height: 100vh;
  `}
`;

const HeroGrid = styled(Container)`
  && {
    align-items: center;

    ${media.lg`
      grid-gap: ${getSpace('normal2')};
    `}
  }
`;

const HeroText = styled.div`
  grid-column: 1 / span 4;
  ${media.md`
    grid-column: 2 / span 6;
  `}
  ${media.lg`
    grid-column: 2 / span 6;
  `}
`;

const HeroImage = styled.div`
  grid-column: 1 / span 4;
  justify-self: center;
  max-height: ${pxToRem(320)};
  max-width: ${pxToRem(320)};
  mix-blend-mode: darken;
  position: relative;
  width: 100%;

  > div {
    position: relative;
    height: 400px;
    overflow: hidden;
  }

  svg {
    height: ${pxToRem(640)} !important;
    left: 50%;
    position: absolute;
    top: 30%;
    width: ${pxToRem(640)} !important;
    transform: translate3d(-50%, -50%, 0) !important;
  }
  ${media.md`
    grid-column: 1 / span 8;
    max-height: ${pxToRem(462)};
    max-width: ${pxToRem(433)};
    > div {
      height: 600px;
    }
    svg {
      height: ${pxToRem(740)} !important;
      position: absolute;
      top: 35%;
      width: ${pxToRem(740)} !important;
   
    }
  `}
  ${media.lg`
    max-height: 100%;
    grid-column: 8 / span 5;
    > div {
      height: 866px;
    }
    svg {
      height: ${pxToRem(960)} !important;
      left: -16rem;
      top: -9rem;
      width: ${pxToRem(960)} !important;
      transform: translate3d(0, 0, 0) !important;
    }
  `}
`;

const SmallerHeroImage = styled.div`
  grid-column: 1 / span 4;
  justify-self: center;
  max-height: ${pxToRem(410)};
  max-width: ${pxToRem(320)};
  mix-blend-mode: darken;
  padding-top: ${getSpace('normal3')};
  position: relative;
  width: 100%;

  > div {
    height: ${pxToRem(410)};
    overflow: hidden;
    position: relative;
  }

  svg {
    transform: translate3d(0, 0, 0) scale(1.5) !important;
  }

  ${media.md`
        grid-column: 3 / span 4;
        max-height: ${pxToRem(320)};
      `}

  ${media.lg`
        grid-column: 8 / span 5;
        max-height: ${pxToRem(709)};
        max-width: unset;

        > div {
          height: ${pxToRem(560)};
        }

        svg {
          transform: translate3d(0, 0, 0) scale(1.5) !important;
        }
      `}
`;
