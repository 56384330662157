import styled from 'styled-components';

import { AnimateWhenInView } from '@yoweb/ui/components/AnimateIn';
import { GridItem } from '@yoweb/ui/components/Grid/MarketingGrid';
import { Photo } from '@yoweb/ui/components/marketing/Photo';
import { Container } from '@yoweb/ui/components/containers';
import { BodyHeading } from '@yoweb/ui/components/marketing/MarketingTexts';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { media } from '@yoweb/ui/styles/utils';

type RoundedImageProps = {
  src: string;
  alt?: string;
  title?: string;
};

export const RoundedImage = ({ src, alt, title }: RoundedImageProps) => (
  <StyledContainer withGrid data-testid="rounded-image-section">
    {title && (
      <GridItem gridRow="1" gridColumn={{ _: '1 / span 4', md: '1 / span 8', lg: '1 / span 13' }}>
        <BodyHeading textAlign="center" size={{ _: 'lg', md: 'xl' }}>
          {title}
        </BodyHeading>
      </GridItem>
    )}
    <GridItem
      gridRow={title ? '2' : '1'}
      gridColumn={{ _: '1 / span 4', md: '1 / span 8', lg: '2 / span 10' }}
    >
      <AnimateWhenInView
        config={{
          y: {
            animate: true,
          },
          opacity: {
            duration: 0.33,
          },
        }}
      >
        <Photo src={src} width={1226} height={800} alt={alt} />
      </AnimateWhenInView>
    </GridItem>
  </StyledContainer>
);

const StyledContainer = styled(Container)`
  && {
    grid-row-gap: ${getSpace('normal4')};

    ${media.md`
      grid-row-gap: ${getSpace('medium2')};
    `}
  }
`;
