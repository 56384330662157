import { useRef, useEffect } from 'react';
import LottieReact from 'lottie-react';
import type { LottieComponentProps, LottieRefCurrentProps } from 'lottie-react';

type LottieProps = LottieComponentProps & { className?: string; play?: boolean };

export const Lottie = ({
  animationData,
  className,
  autoPlay = true,
  loop = true,
  play,
  ...rest
}: LottieProps) => {
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  const options = {
    animationData,
    autoplay: autoPlay,
    loop,
    ...rest,
  };

  useEffect(() => {
    if (autoPlay) {
      return;
    }
    if (play) {
      lottieRef.current?.play();
    } else {
      lottieRef.current?.stop();
    }
  }, [play, autoPlay]);

  return <LottieReact className={className} lottieRef={lottieRef} {...options} />;
};

export default Lottie;
